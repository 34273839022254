import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { handlePaymentSuccess, setPaymentState } from '../services/PaymentService';
import './PaymentRedirect.css';

const PaymentRedirectPage = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const handlePaymentRedirect = async () => {
            const status = searchParams.get('collection_status');
            const paymentId = searchParams.get('payment_id');
            const storeRoute = localStorage.getItem('currentStore');

            if (!status || !paymentId || !storeRoute) {
                setPaymentState({
                    status: 'error',
                    error: 'Faltan parámetros requeridos'
                });
                window.close();
                return;
            }

            try {
                const result = await handlePaymentSuccess({
                    status,
                    paymentId,
                    storeRoute
                });

                setPaymentState({
                    status: result.status,
                    orderId: result.orderId,
                    storeRoute: storeRoute
                });

                if (window.opener) {
                    window.opener.postMessage({
                        type: 'PAYMENT_RESULT',
                        payload: {
                            status: result.status,
                            orderId: result.orderId,
                            storeRoute: storeRoute
                        }
                    }, window.location.origin);
                }
            } catch (error) {
                setPaymentState({
                    status: 'error',
                    error: error.message
                });
            } finally {
                window.close();
            }
        };

        handlePaymentRedirect();
    }, [searchParams]);

    return null;
};

export default PaymentRedirectPage; 