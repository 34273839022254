import React, { useContext, useRef, useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faArrowsAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { CartContext } from '../Cart/CartContext';

const DraggableProductItem = ({ product, isOwner, openEditProductModal, openModal }) => {
    const [controlsCollapsed, setControlsCollapsed] = React.useState(true);
    const collapseTimeout = React.useRef(null);

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: product.uuid,
        disabled: product.isHidden || product.stock <= 0 // Deshabilitamos el arrastre si el product está oculto
    });

    const { addToCart, removeFromCart, cart } = useContext(CartContext);
    
    // Get the quantity of this product in the cart
    const cartItem = cart.find(item => item._id === product._id);
    const quantity = cartItem ? cartItem.amount : 0;

    const crearEfectoparticles = (e) => {
        const cantidadparticles = 20;
        for (let i = 0; i < cantidadparticles; i++) {
            let particle = document.createElement('div');
            particle.style.left = `${e.clientX}px`;
            particle.style.top = `${e.clientY}px`;
            particle.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
            document.body.appendChild(particle);
            particle.className = 'particle';

            const animation = particle.animate([
                { transform: 'scale(3)', opacity: 1 },
                { transform: `scale(0) translate(${Math.random() * 200 - 100}px, ${Math.random() * 200 - 100}px)`, opacity: 0 }
            ], {
                duration: Math.random() * 500 + 300,
                easing: 'ease-out',
                iterations: 1
            });

            animation.onfinish = () => particle.remove();
        }
    };

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0 : 1,
        cursor: isDragging ? 'grabbing' : 'grab',
        zIndex: isDragging ? 10 : 1,
    };

    const handleControlsInteraction = () => {
        setControlsCollapsed(false);
        // Reset the collapse timeout
        if (collapseTimeout.current) {
            clearTimeout(collapseTimeout.current);
        }
        collapseTimeout.current = setTimeout(() => {
            setControlsCollapsed(true);
        }, 5000);
    };

    // Cleanup timeout on unmount
    React.useEffect(() => {
        return () => {
            if (collapseTimeout.current) {
                clearTimeout(collapseTimeout.current);
            }
        };
    }, []);

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`image-item ${(product.isHidden || product.stock <= 0) ? 'hidden-product' : ''} ${product.isFeatured ? 'featured-product' : ''}`}
            {...attributes}
        >
            {product.isHidden && <div className="hidden-label">Oculto</div>}
            {product.stock <= 0 && !product.isHidden && <div className="out-of-stock-label">Sin Stock</div>}
            <div className="image-container">
                <img src={product.imageUrls.thumbnail} alt={product.name} onClick={() => openModal(product)} />
                <div className={`product-controls ${controlsCollapsed ? 'controls-collapsed' : ''}`}>
                    {quantity > 0 && (
                        <button 
                            className="control-btn remove-btn"
                            onClick={(e) => {
                                removeFromCart(product._id);
                                handleControlsInteraction();
                                e.stopPropagation();
                            }}
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                    )}
                    {quantity > 0 && (
                        <span 
                            className={`quantity-badge ${controlsCollapsed ? 'standalone' : ''}`}
                            onClick={(e) => {
                                handleControlsInteraction();
                                e.stopPropagation();
                            }}
                        >
                            {quantity}
                        </span>
                    )}
                    {(quantity === 0 || !controlsCollapsed) && (
                        <button 
                            className="control-btn add-btn"
                            onClick={(e) => {
                                if (!product.isHidden && product.stock > 0 && quantity < product.stock) {
                                    addToCart(product);
                                    handleControlsInteraction();
                                    crearEfectoparticles(e);
                                }
                                e.stopPropagation();
                            }}
                            disabled={product.isHidden || product.stock <= 0 || quantity >= product.stock}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    )}
                </div>
                <div className="price-tag">
                    <span className="price-text">${product.price}</span>
                </div>
            </div>
            <p>{product.name}</p>
            {isOwner && (
                <>
                    <p className='product-stock'>Stock: {product.stock}</p>
                    {!product.isHidden && product.stock > 0 && 
                        <div className="move-icon-container" {...listeners}> {/* Aplicamos los listeners solo al botón */}
                            <FontAwesomeIcon icon={faArrowsAlt} className="move-icon" />
                        </div>}
                    <div className="edit-icon-container" onClick={() => openEditProductModal(product)}>
                        <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" />
                    </div>
                </>
            )}
        </div>
    );
};

export default DraggableProductItem;
