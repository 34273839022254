import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBasketShopping, faAngleDown } from '@fortawesome/free-solid-svg-icons';

// Servicios
import storeServices from '../../services/StoreServices';
import authService from '../../services/AuthServices';
import { openLoginModal } from '../../services/UserService';

// Contextos
import { CartContext } from '../Cart/CartContext';
import { useLoginModal } from '../Login/LoginModalContext';

// Componentes
import CartModal from '../Cart/CartModal';
import LoginModal from '../Login/LoginModal';
import StoreDrawer from './StoreDrawer';

// Assets
import defaultLogo from '../../assets/Logo Prodexpo pequeño.png';
import './Header.css';

const Header = ({ storeLogo, storeRoute }) => {
  const navigate = useNavigate();
  const { cart } = useContext(CartContext);
  const { isLoginModalOpen, closeLoginModal } = useLoginModal();
  const menuRef = useRef(null);

  // Estados
  const [cartState, setCartState] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [userStores, setUserStores] = useState([]);
  const [menuAnimation, setMenuAnimation] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentStore, setCurrentStore] = useState(null);
  const location = useLocation();

  // Cálculos
  const productAmount = cart.reduce((acc, product) => acc + product.amount, 0);

  // Efectos
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowButtons(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const fetchStores = async () => {
      if (isLoggedIn) {
        try {
          const stores = await storeServices.getStores();
          setUserStores(stores);
        } catch (error) {
          console.error('Error fetching stores', error);
        }
      }
    };
    fetchStores();
  }, [isLoggedIn]);

  useEffect(() => {
    if (menuAnimation) {
      const timer = setTimeout(() => setMenuAnimation(false), 500);
      return () => clearTimeout(timer);
    }
  }, [menuAnimation]);

  // Manejadores de eventos
  const handleMyStoreClick = () => {
    if (userStores?.length > 0) {
      navigate(`/${userStores[0].route}`);
      setShowButtons(false);
    }
  };

  const handleMyOrdersClick = () => {
    if (userStores?.length > 0) {
      navigate(`/${userStores[0].route}/orders`);
      setShowButtons(false);
    }
  };

  const toggleButtons = () => {
    if (!showButtons) {
      setMenuAnimation(true);
    }
    setShowButtons(!showButtons);
  };

  const handleLoginClick = () => {
    openLoginModal();
    setShowButtons(false);
  };

  const handleLogout = async () => {
    await authService.logout();
    setIsLoggedIn(false);
    window.location.reload();
  };

  const handleDrawerToggle = async () => {
    try {
      // Obtener la ruta actual
      const currentPath = location.pathname.split('/')[1];
      
      if (currentPath) {
        // Obtener información de la tienda actual
        const storeData = await storeServices.getStoreByRoute(currentPath);
        console.log('Información de la tienda:', storeData);
        setCurrentStore(storeData);
      }
    } catch (error) {
      console.error('Error al obtener datos de la tienda:', error);
    }
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Renderizado del menú de usuario
  const renderUserMenu = () => (
    <div className='menu-content'>
      {!isLoggedIn ? (
        <button className='login-btn' onClick={handleLoginClick}>
          Iniciar sesión
        </button>
      ) : (
        <>
          <button className='menu-btn' onClick={handleMyStoreClick}>
            Mi tienda
          </button>
          <button className='menu-btn' onClick={handleMyOrdersClick}>
            Mis pedidos
          </button>
          <button className='menu-btn logout-btn' onClick={handleLogout}>
            Cerrar sesión
          </button>
        </>
      )}
    </div>
  );

  // Add this function to check if we should show the drawer toggle
  const shouldShowDrawerToggle = () => {
    const pathSegments = location.pathname.split('/');
    // Don't show on home page (when path is just "/")
    if (pathSegments.length === 2 && pathSegments[1] === '') {
      return false;
    }
    // Show only if we're in a store route (has 2 segments) and the second segment is empty
    return pathSegments.length === 2 || 
           (pathSegments.length === 3 && pathSegments[2] === '');
  };

  return (
    <div className='header'>
      {/* Botón de menú usuario */}
      <div className='menu-button' ref={menuRef} onClick={toggleButtons}>
        <FontAwesomeIcon 
          className='header-icon' 
          icon={faUser} 
          style={{ color: "#74C0FC" }} 
        />
        <div className={`menu-wrapper ${menuAnimation && showButtons ? 'swing-in-top-fwd' : ''}`}>
          {showButtons && renderUserMenu()}
        </div>
      </div>

      {/* Logo */}
      <img 
        src={storeLogo || defaultLogo} 
        alt="Logo" 
        className='logo' 
        onClick={() => navigate(storeRoute || '/')} 
      />

      {/* Botón de carrito */}
      <button 
        onClick={() => setCartState('cartActionEntering')} 
        className='cart-btn'
      >
        <div className='cart-count'>{productAmount}</div>
        <FontAwesomeIcon 
          className='cart-icon' 
          icon={faBasketShopping} 
          style={{ color: "#74C0FC" }} 
        />
      </button>

      {/* Modales */}
      {cartState && (
        <CartModal 
          cartEntranceState={cartState} 
          closeCart={() => setCartState(null)} 
        />
      )}
      {isLoginModalOpen && <LoginModal onClose={closeLoginModal} />}

      <StoreDrawer 
        isOpen={isDrawerOpen} 
        storeName={currentStore?.name || ''}
        stores={userStores}
        storeLogo={storeLogo}
      />
      {shouldShowDrawerToggle() && (
        <div className='drawer-toggle'>
          <FontAwesomeIcon 
            className={`header-icon ${isDrawerOpen ? 'rotate' : ''}`}
            icon={faAngleDown} 
            style={{ color: "#74C0FC", cursor: 'pointer', height: '25px' }} 
            onClick={handleDrawerToggle}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
