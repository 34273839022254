import React, { useState, useEffect, useCallback, useRef } from 'react';
import OrderService from '../services/OrderService';
import OrderDetailsModal from '../components/Orders/OrderDetailsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUser, faFilter, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "./MyOrdersPage.css";

const OrderSkeleton = () => (
    <div className="order-item skeleton">
        <div className="skeleton-content">
        </div>
    </div>
);

const MyOrdersPage = ({ store }) => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [sortOption, setSortOption] = useState('desc');
    const [filterState, setFilterState] = useState('');
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [isLoading, setIsLoading] = useState(false);

    const loadingRef = useRef(false);  // Ref para manejar loading sin causar bucles

    const convertToUTCDate = (date) => {
        if (!date || isNaN(date.getTime())) {
            console.error("Fecha inválida:", date);
            return "Fecha inválida";
        }
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(date.getTime() + userTimezoneOffset);
        return adjustedDate.toISOString().split('T')[0];
    };

    // Función de fetch optimizada
    const fetchOrders = useCallback(async (page = 1) => {
        if (loadingRef.current) return;
        loadingRef.current = true;  // Evitar múltiples llamadas
        setIsLoading(true);  // Indicar que la carga está en proceso

        try {
            const response = await OrderService.getStoreOrders(
                store,
                page,
                sortOption,
                filterState,
                startDate ? convertToUTCDate(startDate) : '',
                endDate ? convertToUTCDate(new Date(endDate.setHours(23, 59, 59, 999))) : ''
            );
            const { data, totalPages } = response;

            const localData = data.map(order => ({
                ...order,
                createdAt: order.createdAt
            }));

            setOrders(prevOrders => page === 1 ? localData : [...prevOrders, ...localData]);
            setHasMore(page < totalPages);
            setLoadedPage(page);
        } catch (error) {
            console.error('Error al obtener las órdenes:', error);
        } finally {
            loadingRef.current = false;
            setIsLoading(false);  // Indicar que la carga ha terminado
        }
    }, [store, sortOption, filterState, startDate, endDate]); // Asegúrate de incluir todas las dependencias necesarias


    // Manejar el scroll con throttle para evitar llamadas duplicadas
    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 500 && hasMore && !loadingRef.current
        ) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    }, [hasMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    // Hacer fetch cuando cambie la página
    useEffect(() => {
        if (currentPage > loadedPage) {
            fetchOrders(currentPage);
        }
    }, [currentPage, loadedPage, fetchOrders]);

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilterState(e.target.value);
    };

    const handleOrderClick = (orderId) => {
        const order = orders.find(o => o._id === orderId);
        setSelectedOrder(order);
    };

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleUpdateStatus = async (orderId, newStatus) => {
        try {
            const data = await OrderService.updateOrderStatus(orderId, newStatus);
            if (data.success) {
                setOrders(orders.map(o => (o._id === orderId ? { ...o, status: newStatus } : o)));
            }
        } catch (error) {
            console.error('Error al actualizar el estado del pedido:', error);
        }
    };

    const handleCloseModal = () => {
        setSelectedOrder(null);
    };

    const closeModal = () => {
        setShowFilterMenu(false);
    };

    const handleDateRangeChange = (update) => {
        setDateRange(update);
    };

    const handleSaveFilters = () => {
        setCurrentPage(1);
        fetchOrders();
        setShowFilterMenu(false);
    };

    return (
        <>
            <div className="orders-page">
                <div className="orders-header-container">
                    <h1 className="orders-header">Pedidos</h1>
                </div>

                {showFilterMenu && (
                    <>
                        <div className="filter-modal-overlay" onClick={closeModal}></div>
                        <div className="filter-modal">
                            <button className="filter-modal-close-button" onClick={closeModal}>×</button>
                            <h2>Filtros</h2>
                            <div className='filter-menu'>
                                <div className="filter-option">
                                    <label htmlFor="sortOrder">Ordenar por:</label>
                                    <select id="sortOrder" value={sortOption} onChange={handleSortChange}>
                                        <option value="desc">Más reciente primero</option>
                                        <option value="asc">Más antiguo primero</option>
                                    </select>
                                </div>
                                <div className="filter-option">
                                    <label htmlFor="filterStatus">Estado del pedido:</label>
                                    <select id="filterStatus" value={filterState} onChange={handleFilterChange}>
                                        <option value="">Todos los estados</option>
                                        <option value="pending">Pendiente</option>
                                        <option value="processing">En proceso</option>
                                        <option value="shipped">Enviado</option>
                                        <option value="delivered">Entregado</option>
                                        <option value="cancelled">Cancelado</option>
                                    </select>
                                </div>
                                <div className="filter-option filter-datepicker-container">
                                    <div className="datepicker-container">
                                        <label htmlFor="dateRange">Periodo:</label>
                                        <DatePicker
                                            className='filter-date-picker'
                                            selected={startDate}
                                            onChange={handleDateRangeChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Fecha desde - Fecha hasta"
                                        />
                                        <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon-inside" />
                                    </div>
                                </div>
                            </div>
                            <button className="save-filter-button" onClick={handleSaveFilters}>Guardar Filtros</button>                        </div>
                    </>
                )}

                <div className="orders-container">
                    <button className="filter-btn" onClick={toggleFilterMenu}>
                        <span>Filtrar</span>
                        <FontAwesomeIcon icon={faFilter} />
                    </button>
                    {isLoading ? (
                        Array.from({ length: 5 }).map((_, index) => <OrderSkeleton key={index} />)
                    ) : (
                        orders.length === 0 && !hasMore ? (
                            <h2 className="no-orders-message">No se realizó ningún pedido a esta tienda</h2>
                        ) : (
                            orders.map((order) => (
                                <div key={order._id} className="order-item">
                                    <div className='order-details'>
                                        <div className="order-header">
                                            <span className="order-number">#{order.orderNumber}</span>
                                            <span className="order-date">
                                                {new Date(order.createdAt).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '/')}
                                            </span>
                                            <button className="view-details-btn" onClick={() => handleOrderClick(order._id)}>
                                                <FontAwesomeIcon icon={faEye} />
                                                <span>Ver Detalles</span>
                                            </button>
                                        </div>
                                        <div className="order-sub-header">
                                            <FontAwesomeIcon icon={faUser} />
                                            <span className="order-customer">
                                                {`${order.user.firstName} ${order.user.lastName}`}
                                            </span>
                                        </div>
                                        <div className="order-products">
                                            {order.products.map((product, index) => (
                                                <div key={index} className="product-item">
                                                    <div className="product-thumbnail">
                                                        {product.productId?.imageUrls?.thumbnail && (
                                                            <img
                                                                src={product.productId.imageUrls.thumbnail}
                                                                alt={product.productId.name}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="product-info">
                                                        <span id="order-product-name">{product.productId?.name || 'Producto'}</span>
                                                        <span id="order-product-units">{product.quantity} unidad/es</span>
                                                        <span id="order-product-price">${product.productId?.price || 'N/A'}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="order-summary">
                                            <span className="order-total">Total: ${order.subTotal}</span>
                                            <div className="order-status-dropdown">
                                                <span className="order-status-label">Estado: </span>
                                                <select
                                                    className={`order-status-${order.status}`} // Asignar clase dinámica
                                                    value={order.status}
                                                    onChange={(e) => handleUpdateStatus(order._id, e.target.value)}
                                                >
                                                    <option value="pending">Pendiente</option>
                                                    <option value="processing">En proceso</option>
                                                    <option value="shipped">Enviado</option>
                                                    <option value="delivered">Entregado</option>
                                                    <option value="cancelled">Cancelado</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order-customer-info" id='order-list-customer-info'>
                                        <h3>Información del Cliente</h3>
                                        <p><strong>Nombre:</strong> {order.user.firstName} {order.user.lastName}</p>
                                        <p><strong>Dirección:</strong> {order.user.address}, {order.user.city}, {order.user.state}, {order.user.country}, {order.user.postalCode}</p>
                                        <p><strong>Teléfono:</strong> {order.user.phoneNumber}</p>
                                    </div>
                                </div>
                            ))
                        )
                    )}
                </div>

                {selectedOrder && (
                    <OrderDetailsModal
                        order={selectedOrder}
                        onClose={handleCloseModal}
                        onUpdateStatus={handleUpdateStatus}
                    />
                )}
            </div>
        </>
    );
};

export default MyOrdersPage;
