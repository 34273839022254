import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { faClipboard as faClipboardRegular } from '@fortawesome/free-regular-svg-icons';
import { QRCodeSVG } from 'qrcode.react';
import defaultLogo from '../../assets/Logo Prodexpo pequeño.png';
import pxpoLogo from '../../assets/Logo Prodexpo Icon.png';
import './StorePanel.css';
import { toPng } from 'html-to-image';

const StorePanel = ({ isOpen, onClose, storeName, storeUrl, storeLogo }) => {
  const [showCopied, setShowCopied] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const panelRef = useRef(null);
  const textareaRef = useRef(null);

  const handleShare = async () => {
    try {
      setIsClicked(true);
      setTimeout(() => setIsClicked(false), 300);
      await navigator.clipboard.writeText(storeUrl);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Error al copiar:', err);
    }
  };

  const handleShareImage = async () => {
    try {
      const shareButton = panelRef.current.querySelector('.share-button');
      const descriptionSection = panelRef.current.querySelector('.description-section');
      const textarea = textareaRef.current;
      
      // Hide elements before capturing
      shareButton.style.display = 'none';
      if (!textarea.value.trim()) {
        descriptionSection.style.display = 'none';
      }

      const dataUrl = await toPng(panelRef.current, {
        quality: 0.95,
        backgroundColor: 'white'
      });

      // Restore elements after capturing
      shareButton.style.display = 'block';
      descriptionSection.style.display = 'block';

      const link = document.createElement('a');
      link.download = `${storeName || 'store'}-qr.png`;
      link.href = dataUrl;
      link.click();
    } catch (err) {
      console.error('Error al generar la imagen:', err);
    }
  };

  const handleBackdropClick = (e) => {
    if (e.target.className === 'modal-backdrop') {
      onClose();
    }
  };

  const handleTextareaInput = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div className="store-panel" ref={panelRef}>        
        
        <div className="store-logo">
          <img 
            src={storeLogo || defaultLogo} 
            alt={`${storeName || 'ProdExpo'} logo`}
            className="store-logo-img"
            />
        </div>
        
        <div className="store-name">{storeName}</div>
        
        <div className={`qr-section ${isClicked ? 'clicked' : ''}`} onClick={handleShare}>
          <QRCodeSVG 
            value={storeUrl}
            size={228}
            level="H"
            title='QR de la tienda'
            imageSettings={{
                src: pxpoLogo,
                height: 50,
                width: 50,
                excavate: false,
            }}
            fgColor="#000000"
            />
            <div className="store-url">
                <span>{storeUrl}</span>
                <FontAwesomeIcon icon={faClipboardRegular} className="fa-lg" style={{color: "#444343", backgroundColor: "transparent"}} />
                {showCopied && <div className="copied-toast">¡Copiado!</div>}
            </div>
        </div>
        
        
        <div className="description-section">
          <textarea 
            ref={textareaRef}
            type="text" 
            placeholder="Descripción de la tienda..." 
            className="store-description-input"
            onInput={handleTextareaInput}
          />
        </div>

        <button className="share-button" onClick={handleShareImage}>
            <FontAwesomeIcon icon={faShare} /> Compartir
        </button>
      </div>
    </div>,
    document.body
  );
};

export default StorePanel; 