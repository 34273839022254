import authService from './AuthServices'; 

const API_URL = process.env.REACT_APP_API_URL;

// Obtener órdenes del vendedor con paginación
const getStoreOrders = async (route, page = 1, sortOption = 'asc', filterState = '', startDate = '', endDate = '', limit = 10) => {
  const token = localStorage.getItem('accessToken');
  const refreshToken = document.cookie.includes('refreshToken');

  if (!token && !refreshToken) {
    return { data: [], totalPages: 0 };
  }

  try {
    const url = `${API_URL}/orders/${route}?page=${page}&limit=${limit}&orderDate=${sortOption}&filterState=${filterState}&startDate=${startDate}&endDate=${endDate}`;
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      return authService.handle401Error(() => getStoreOrders(route, page, sortOption, filterState, startDate, endDate, limit));
    }

    return await response.json();
  } catch (error) {
    console.error('Error en OrderService.getStoreOrders:', error.message);
    throw error;
  }
};

// Actualizar el estado de un pedido
const updateOrderStatus = async (orderId, newStatus) => {
  try {
    const token = localStorage.getItem('accessToken');  // Tomamos el token del localStorage
    const response = await fetch(`${API_URL}/orders/${orderId}/status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,  // Autenticamos la solicitud
      },
      body: JSON.stringify({ status: newStatus }),  // Enviamos el nuevo estado
    });

    if (!response.ok) {
      throw new Error('Error al actualizar el estado del pedido');
    }

    const data = await response.json();
    return data;  // Retornamos la respuesta del servidor
  } catch (error) {
    console.error('Error en OrderService.updateOrderStatus:', error);
    throw error;
  }
};

export const getOrderDetails = async (orderId) => {
  try {
    const response = await fetch(`${API_URL}/orders/order/${orderId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    if (!response.ok) {
      throw new Error('Error al obtener los detalles de la orden');
    }

    return await response.json();
  } catch (error) {
    console.error('Error en getOrderDetails:', error);
    throw error;
  }
};

const OrderService = {
  getStoreOrders,
  updateOrderStatus,
  getOrderDetails,
};

export default OrderService;
