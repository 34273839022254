import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header/Header';
import PaymentFailure from '../components/Payment/PaymentFailure';
import { getOrderDetails } from '../services/OrderService';
import { checkPaymentStatus } from '../services/PaymentService';
import './ThankYouPage.css';

const ThankYouPage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const storeRoute = location.pathname.split('/')[1];

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await getOrderDetails(orderId);
        if (response.success) {
          setOrderData(response.data);
          // Verificar el estado del pago
          const paymentResult = await checkPaymentStatus(orderId);
          setPaymentStatus(paymentResult.status);
        } else {
          setError('No se pudo obtener la información de la orden');
        }
      } catch (error) {
        console.error('Error al obtener detalles de la orden:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleRetry = () => {
    const storeRoute = location.pathname.split('/')[1];
    navigate(`/${storeRoute}/checkout`);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Cargando detalles de la orden...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>Error: {error}</p>
        <button onClick={() => navigate(`/${storeRoute}/checkout`)}>
          Volver al checkout
        </button>
      </div>
    );
  }

  if (paymentStatus === 'rejected') {
    return <PaymentFailure error={error} onRetry={handleRetry} />;
  }

  if (paymentStatus === 'pending') {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Verificando el estado del pago...</p>
      </div>
    );
  }

  return (
    <div className="thankyou-page">
      <Header />
      <div className="thankyou-container">
        <div className="success-header">
          <h1>¡Gracias por tu compra!</h1>
          {paymentStatus === 'approved' ? (
            <p>Tu pago ha sido procesado exitosamente</p>
          ) : (
            <p>Estamos procesando tu pago</p>
          )}
        </div>

        <div className="order-details">
          <h2>Detalles de la Orden</h2>
          <p><strong>Número de Orden:</strong> {orderData._id}</p>
          
          <h3>Datos de Envío</h3>
          <div className="shipping-details">
            <p><strong>Nombre:</strong> {orderData.user.firstName} {orderData.user.lastName}</p>
            <p><strong>Dirección:</strong> {orderData.user.address}</p>
            <p><strong>Ciudad:</strong> {orderData.user.city}</p>
            <p><strong>Teléfono:</strong> {orderData.user.phoneNumber}</p>
          </div>

          <h3>Productos</h3>
          <div className="purchased-products">
            {orderData.products.map((product, index) => (
              <div key={index} className="product-item">
                <div className="product-image">
                  <img 
                    src={product.productId.imageUrls.thumbnail} 
                    alt={product.productId.name} 
                  />
                </div>
                <div className="product-details">
                  <h4>{product.productId.name}</h4>
                  <p>Cantidad: {product.quantity}</p>
                  <p>Precio: ${product.productId.price}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="order-summary">
            <h3>Resumen</h3>
            <p><strong>Subtotal:</strong> ${orderData.subTotal}</p>
            <p><strong>Total:</strong> ${orderData.total}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
