import authService from './AuthServices';

let openLoginModalCallback = null;

export const setLoginModalCallback = (callback) => {
  openLoginModalCallback = callback;
};

export const openLoginModal = () => {
  if (openLoginModalCallback) {
    openLoginModalCallback();
  }
};
// UserService.js
export const fetchUserData = async () => {
  const token = localStorage.getItem('accessToken');
  const refreshToken = document.cookie.includes('refreshToken');

  if (!token && !refreshToken) {
    return null;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      return authService.handle401Error(() => fetchUserData());
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener datos del usuario:', error);
    throw error;
  }
};

export const updateUserProfile = async (userData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      body: JSON.stringify(userData)
    });

    if (!response.ok) {
      throw new Error('Error al actualizar el perfil');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
