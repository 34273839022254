import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const InputWithError = React.memo(({ name, value, onChange, placeholder, error, type = "text", pattern }) => (
    <div className="input-container">
        <input
            className={`add-product-input ${error ? 'input-error' : ''}`}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            pattern={pattern}
        />
        {error && (
            <div className="error-icon-container">
                <FontAwesomeIcon icon={faInfoCircle} className="error-icon" />
                <span className="error-tooltip">{error}</span>
            </div>
        )}
    </div>
));

export default InputWithError;
