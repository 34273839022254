import React, { useState } from "react";
import authService from '../../services/AuthServices';
import './LoginModal.css';
import InputWithError from './InputWithError';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth } from '../../config/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import CreateStoreModal from '../Store/CreateStoreModal';
import { useLoginModal } from './LoginModalContext';

// Crear un componente para el prompt de crear tienda
const CreateStorePrompt = ({ onCreateStore, onSkip }) => {
    const modalRef = React.useRef(null);

    React.useEffect(() => {
        if (modalRef.current) {
            const modalContent = modalRef.current.querySelector('.add-product-modal-content');
            if (modalContent) {
                setTimeout(() => {
                    createRegisteredParticles(modalContent);
                }, 100); // Pequeño retraso para asegurar que el modal esté renderizado
            }
        }
    }, []);

    return (
        <div className="add-product-modal-backdrop" onClick={onSkip} ref={modalRef}>
            <button onClick={onSkip} className="close-button-modal">X</button>
            <div className="add-product-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>¡Cuenta creada exitosamente!</h2>
                <p>¿Te gustaría crear tu propia tienda?</p>
                <div className="prompt-buttons">
                    <button 
                        onClick={onCreateStore} 
                        className="submit-button"
                    >
                        Crear Tienda
                    </button>
                    <button onClick={onSkip} className="secondary-button">
                        Más tarde
                    </button>
                </div>
            </div>
        </div>
    );
};

function createRegisteredParticles(elemento) {
    const rect = elemento.getBoundingClientRect();
    const cantidadParticles = 40;
    
    for (let i = 0; i < cantidadParticles; i++) {
        let particle = document.createElement('div');
        
        // Determinar posición aleatoria alrededor del modal
        const lado = Math.floor(Math.random() * 4); // 0: arriba, 1: derecha, 2: abajo, 3: izquierda
        let x, y;
        
        switch(lado) {
            case 0: // arriba
                x = rect.left + Math.random() * rect.width;
                y = rect.top;
                break;
            case 1: // derecha
                x = rect.right;
                y = rect.top + Math.random() * rect.height;
                break;
            case 2: // abajo
                x = rect.left + Math.random() * rect.width;
                y = rect.bottom;
                break;
            case 3: // izquierda
                x = rect.left;
                y = rect.top + Math.random() * rect.height;
                break;
        }
        
        particle.style.left = `${x}px`;
        particle.style.top = `${y}px`;
        particle.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
        document.body.appendChild(particle);
        particle.className = 'particle';

        const animation = particle.animate([
            { transform: 'scale(3)', opacity: 1 },
            { transform: `scale(0) translate(${Math.random() * 200 - 100}px, ${Math.random() * 200 - 100}px)`, opacity: 0 }
        ], {
            duration: Math.random() * 1000 + 1300, // Aumentado de 500+300 a 1000+800
            easing: 'ease-out',
            iterations: 1
        });

        animation.onfinish = () => particle.remove();
    }
}

// En el componente principal LoginModal
const LoginModal = ({ onClose }) => {
    const { initialView } = useLoginModal();
    const [isLoginView, setIsLoginView] = useState(initialView === 'login');
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmEmail: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        areaCode: '',
        phoneNumber: '',
        dni: ''
    });

    const [errors, setErrors] = useState({});
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialChar: false,
    });
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [socialData, setSocialData] = useState(null);
    const [showSocialDataForm, setShowSocialDataForm] = useState(false);
    const [showCreateStorePrompt, setShowCreateStorePrompt] = useState(false);
    const [isCreateStoreModalOpen, setIsCreateStoreModalOpen] = useState(false);
    const [showLoginContent, setShowLoginContent] = useState(true);
    const { closeLoginModal } = useLoginModal(); // Añade esto

    // Mover las expresiones regulares a constantes
    const REGEX = {
        email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
        name: /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));

        // Limpiar el error del campo actual
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));

        if (name === 'password') {
            setPasswordRequirements({
                minLength: value.length >= 8,
                hasUpperCase: /[A-Z]/.test(value),
                hasLowerCase: /[a-z]/.test(value),
                hasNumber: /\d/.test(value),
                hasSpecialChar: /[\W_]/.test(value),
            });
        }

        if (attemptedSubmit) {
            setAttemptedSubmit(false);
        }
    };

    const PasswordRequirements = ({ requirements }) => {
        const getColor = (condition) => (condition ? 'green' : 'red');

        return (
            <div>
                <label style={{ marginBottom: '5px', display: 'block', textAlign: 'left', fontSize: '14px' }}>
                    Requisitos de la contraseña:
                </label>
                <ul className="password-requirements">
                    <li style={{ color: getColor(requirements.minLength) }}>
                        Al menos 8 caracteres
                    </li>
                    <li style={{ color: getColor(requirements.hasUpperCase) }}>
                        Al menos una letra mayúscula
                    </li>
                    <li style={{ color: getColor(requirements.hasLowerCase) }}>
                        Al menos una letra minúscula
                    </li>
                    <li style={{ color: getColor(requirements.hasNumber) }}>
                        Al menos un número
                    </li>
                    <li style={{ color: getColor(requirements.hasSpecialChar) }}>
                        Al menos un carácter especial
                    </li>
                </ul>
            </div>
        );
    };

    const validateFields = (isSocialForm = false) => {
        const newErrors = {};

        // Validaciones comunes para registro normal y social
        if (!isLoginView || isSocialForm) {
            if (!formData.firstName) newErrors.firstName = 'Este campo es necesario';
            else if (!REGEX.name.test(formData.firstName)) newErrors.firstName = 'Solo se permiten letras y espacios';

            if (!formData.lastName) newErrors.lastName = 'Este campo es necesario';
            else if (!REGEX.name.test(formData.lastName)) newErrors.lastName = 'Solo se permiten letras y espacios';

            if (!/^\d{7,8}$/.test(formData.dni)) newErrors.dni = 'El DNI debe tener entre 7 y 8 dígitos';
            if (!/^\d{2,4}$/.test(formData.areaCode)) newErrors.areaCode = 'El código de área debe tener entre 2 y 4 dígitos';
            if (!/^\d{6,8}$/.test(formData.phoneNumber)) newErrors.phoneNumber = 'El número debe tener entre 6 y 8 dígitos';
        }

        // Validaciones solo para registro normal (no social)
        if (!isSocialForm) {
            if (!isLoginView) {
                // Solo validar formato de email y contraseña en el registro
                if (!formData.email) newErrors.email = 'Este campo es necesario';
                else if (!REGEX.email.test(formData.email)) newErrors.email = 'El formato del email no es válido';

                if (!formData.password) newErrors.password = 'Este campo es necesario';
                else if (!REGEX.password.test(formData.password)) newErrors.password = 'La contraseña no cumple con los requisitos';

                if (formData.email !== formData.confirmEmail) newErrors.confirmEmail = 'Los emails no coinciden';
                if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Las contraseñas no coinciden';
            } else {
                // Para login solo validar que los campos no estén vacíos
                if (!formData.email) newErrors.email = 'Este campo es necesario';
                if (!formData.password) newErrors.password = 'Este campo es necesario';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAttemptedSubmit(true);

        if (!validateFields(false)) return;

        try {
            if (isLoginView) {
                const { accessToken } = await authService.login(formData.email, formData.password);
                localStorage.setItem('accessToken', accessToken);
                onClose();
                window.location.reload();
            } else {
                const response = await authService.register(formData);
                if (response.accessToken) {
                    localStorage.setItem('accessToken', response.accessToken);
                    setShowCreateStorePrompt(true);
                }
            }
        } catch (error) {
            if (isLoginView) {
                setErrors({
                    email: 'Email o contraseña incorrectos',
                    password: 'Email o contraseña incorrectos'
                });
            } else {
                setErrors({ 
                    form: 'Hubo un problema al crear tu cuenta. Por favor, intenta de nuevo.' 
                });
            }
        }
    };

    const handleSocialLogin = async (provider) => {
        try {
            let authProvider;
            switch (provider) {
                case 'google':
                    authProvider = new GoogleAuthProvider();
                    break;
                case 'facebook':
                    authProvider = new FacebookAuthProvider();
                    break;
                default:
                    throw new Error('Proveedor no soportado');
            }

            const result = await signInWithPopup(auth, authProvider);
            const firebaseToken = await result.user.getIdToken();
            const userData = {
                email: result.user.email,
                displayName: result.user.displayName,
                firebaseToken
            };

            // Intentar login social
            try {
                const response = await authService.socialLogin(firebaseToken);
                // Si el login es exitoso, proceder normalmente
                handleSuccessfulLogin(response);
            } catch (error) {
                if (error.message === 'INCOMPLETE_PROFILE') {
                    // Si falta información, mostrar formulario
                    setSocialData(userData);
                    setShowSocialDataForm(true);
                } else {
                    throw error;
                }
            }
        } catch (error) {
            console.error('Error en login social:', error);
            toast.error('Error al iniciar sesión con red social');
        }
    };

    const handleSocialDataSubmit = async (e) => {
        e.preventDefault();
        setAttemptedSubmit(true);

        if (!validateFields(true)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                form: 'Por favor, complete todos los campos requeridos.'
            }));
            return;
        }

        try {
            const response = await authService.completeSocialRegistration({
                ...formData,
                firebaseToken: socialData.firebaseToken
            });
            
            if (response.accessToken) {
                localStorage.setItem('accessToken', response.accessToken);
                if (response.user.isNewUser) {
                    setShowSocialDataForm(false);
                    setShowCreateStorePrompt(true);
                } else {
                    handleSuccessfulLogin(response);
                }
            }
        } catch (error) {
            console.error('Error completando registro social:', error);
            setErrors({
                form: 'Hubo un problema al completar tu registro. Por favor, intenta de nuevo.'
            });
            toast.error('Error al completar el registro');
        }
    };

    const handleSuccessfulLogin = (response) => {
        if (response.accessToken) {
            localStorage.setItem('accessToken', response.accessToken);
            if (response.user.isNewUser) {
                toast.success('¡Bienvenido! Tu cuenta ha sido creada exitosamente.');
            }
            onClose();
            window.location.reload();
        }
    };

    const handleCreateStore = () => {
        setShowSocialDataForm(false);
        setShowCreateStorePrompt(false);
        setIsCreateStoreModalOpen(true);
        setShowLoginContent(false); // Ocultar el contenido del login
    };

    const handleCloseCreateStore = () => {
        setIsCreateStoreModalOpen(false);
        setShowLoginContent(true); // Mostrar el contenido del login
        closeLoginModal();
        onClose();
    };

    // Función para manejar el "Más tarde"
    const handleSkip = () => {
        closeLoginModal();
        onClose();
        window.location.reload();
    };

    // Cuando se muestra el formulario de datos sociales
    if (showSocialDataForm) {
        return (
            <>
                {isCreateStoreModalOpen && (
                    <CreateStoreModal 
                        onClose={handleCloseCreateStore} 
                        onStoreCreated={handleCloseCreateStore}
                    />
                )}
                {showCreateStorePrompt ? (
                    <CreateStorePrompt 
                        onCreateStore={handleCreateStore}
                        onSkip={handleSkip}
                    />
                ) : (
                    <div className="add-product-modal-backdrop" onClick={onClose}>
                        <button onClick={onClose} className="close-button-modal">X</button>
                        <div className="add-product-modal-content" onClick={(e) => e.stopPropagation()}>
                            <form onSubmit={handleSocialDataSubmit}>
                                <h2>Completar registro</h2>
                                {errors.form && <p className="error-message">{errors.form}</p>}
                                <InputWithError
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    placeholder="Nombre"
                                    error={attemptedSubmit ? errors.firstName : ''}
                                />
                                <InputWithError
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    placeholder="Apellido"
                                    error={attemptedSubmit ? errors.lastName : ''}
                                />
                                <InputWithError
                                    name="dni"
                                    value={formData.dni}
                                    onChange={handleChange}
                                    placeholder="DNI"
                                    error={attemptedSubmit ? errors.dni : ''}
                                />
                                <div className="phone-input-container">
                                    <div className="phone-group">
                                        <span className="phone-label phone-tag">Telefono:</span>
                                        <span className="phone-label">0</span>
                                        <InputWithError
                                            name="areaCode"
                                            value={formData.areaCode}
                                            onChange={handleChange}
                                            placeholder="Cód. área"
                                            error={attemptedSubmit ? errors.areaCode : ''}
                                        />
                                    </div>
                                    <div className="phone-group">
                                        <span className="phone-label">15</span>
                                        <InputWithError
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            placeholder="Número"
                                            error={attemptedSubmit ? errors.phoneNumber : ''}
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="submit-button">
                                    Completar Registro
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </>
        );
    }

    // Renderizado condicional usando el nuevo componente
    if (showCreateStorePrompt) {
        return (
            <>
                {isCreateStoreModalOpen && (
                    <CreateStoreModal 
                        onClose={handleCloseCreateStore} 
                        onStoreCreated={handleCloseCreateStore}
                    />
                )}
                <CreateStorePrompt 
                    onCreateStore={handleCreateStore}
                    onSkip={handleSkip}
                />
            </>
        );
    }

    // Return principal
    return (
        <>
            {isCreateStoreModalOpen && (
                <CreateStoreModal 
                    onClose={handleCloseCreateStore} 
                    onStoreCreated={handleCloseCreateStore}
                />
            )}
            {showLoginContent && (
                <div className="add-product-modal-backdrop" onClick={onClose}>
                    <button onClick={onClose} className="close-button-modal">X</button>
                    <div className="add-product-modal-content" onClick={(e) => e.stopPropagation()}>
                        <form className="add-product-form" onSubmit={handleSubmit}>
                            <h2>{isLoginView ? 'Iniciar sesión' : 'Crear cuenta'}</h2>
                            {errors.form && <p className="error-message">{errors.form}</p>}
                            {!isLoginView && (
                                <>
                                    <InputWithError
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        placeholder="Nombre"
                                        error={attemptedSubmit ? errors.firstName : ''}
                                    />
                                    <InputWithError
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        placeholder="Apellido"
                                        error={attemptedSubmit ? errors.lastName : ''}
                                    />
                                    <InputWithError
                                        name="dni"
                                        value={formData.dni}
                                        onChange={handleChange}
                                        placeholder="DNI"
                                        error={attemptedSubmit ? errors.dni : ''}
                                    />
                                    <div className="phone-input-container">
                                        <div className="phone-group">
                                            <span className="phone-label phone-tag">Telefono:</span>
                                            <span className="phone-label">0</span>
                                            <InputWithError
                                                name="areaCode"
                                                value={formData.areaCode}
                                                onChange={handleChange}
                                                placeholder="Cód. área"
                                                error={attemptedSubmit ? errors.areaCode : ''}
                                            />
                                        </div>
                                        <div className="phone-group">
                                            <span className="phone-label">15</span>
                                            <InputWithError
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                                onChange={handleChange}
                                                placeholder="Número"
                                                error={attemptedSubmit ? errors.phoneNumber : ''}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <InputWithError
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Email"
                                error={attemptedSubmit ? errors.email : ''}
                            />
                            {!isLoginView && (
                                <InputWithError
                                    name="confirmEmail"
                                    value={formData.confirmEmail}
                                    onChange={handleChange}
                                    placeholder="Confirmar Email"
                                    error={attemptedSubmit ? errors.confirmEmail : ''}
                                />
                            )}
                            <InputWithError
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Contraseña"
                                type="password"
                                error={attemptedSubmit ? errors.password : ''}
                            />
                            {!isLoginView && <PasswordRequirements requirements={passwordRequirements} />}
                            {!isLoginView && (
                                <InputWithError
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    placeholder="Confirmar Contraseña"
                                    type="password"
                                    error={attemptedSubmit ? errors.confirmPassword : ''}
                                />
                            )}
                            <button type="submit" className="submit-button">
                                {isLoginView ? 'Iniciar Sesión' : 'Crear Cuenta'}
                            </button>
                            <div className="social-login-separator">
                            </div>

                            <div className="social-login-buttons">
                                <button
                                    type="button"
                                    className="social-login-button google"
                                    onClick={() => handleSocialLogin('google')}
                                >
                                    <FontAwesomeIcon icon={faGoogle} />
                                    Continuar con Google
                                </button>
                                <button
                                    type="button"
                                    className="social-login-button facebook"
                                    onClick={() => handleSocialLogin('facebook')}
                                >
                                    <FontAwesomeIcon icon={faFacebook} />
                                    Continuar con Facebook
                                </button>
                            </div>
                            {isLoginView ? (
                                <p className="register-link">
                                    ¿No tienes una cuenta? <span onClick={() => setIsLoginView(false)} style={{ cursor: 'pointer', color: '#007bff' }}>¡Regístrate!</span>
                                </p>
                            ) : (
                                <p className="login-link">
                                    ¿Ya tienes cuenta? <span onClick={() => setIsLoginView(true)} style={{ cursor: 'pointer', color: '#007bff' }}>¡Inicia sesión!</span>
                                </p>
                            )}
                            <p className="forgot-password-link">Olvidé mi contraseña</p>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default LoginModal;
