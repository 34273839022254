import React, { useState } from 'react';
import './OrderDetailsModal.css';

const OrderDetailsModal = ({ order, onClose, onUpdateStatus }) => {
  const [newStatus, setNewStatus] = useState(order.status);

  const handleStatusChange = (e) => {
    setNewStatus(e.target.value);
  };

  const handleUpdateClick = () => {
    onUpdateStatus(order._id, newStatus);  // Llamada para actualizar el estado
    onClose();  // Cerrar el modal después de guardar
  };

  return (
    <div className="order-modal-backdrop" onClick={onClose}>
      <div className="order-modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="order-modal-header">Detalles del Pedido #{order.orderNumber}</h2>

        {/* Mostrar número de orden y subtotal */}
        <div className="order-details">

        </div>

        {/* Mostrar tabla de productos */}
        <table className="order-product-table">
          <thead>
            <tr>
              <th></th>
              <th>Producto</th>
              <th>Unidades</th>
              <th>Precio</th>
            </tr>
          </thead>
          <tbody>
            {order.products.map((product) => (
              <tr key={product.productId?._id}>
                <td>
                  <div className="order-thumbnail-circle">
                    {product.productId?.imageUrls?.thumbnail && (
                      <img
                        src={product.productId.imageUrls.thumbnail}
                        alt={product.productId.name}
                        className="order-thumbnail-image"
                      />
                    )}
                  </div>
                </td>
                <td>{product.productId?.name || 'Producto no disponible'}</td>
                <td>{product.quantity}</td>
                <td>${product.productId?.price || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className='order-modal-total'><strong>Total:</strong> ${order.subTotal}</p>

        {/* Mostrar información del cliente */}
        <div className="order-customer-info">
          <h3>Información del Cliente</h3>
          <p><strong>Nombre: </strong> {order.user.firstName} {order.user.lastName}</p>
          <p><strong>Dirección:</strong> {order.user.address}, {order.user.city}, {order.user.state}, {order.user.country}, {order.user.postalCode}</p>
          <p><strong>Teléfono:</strong> {order.user.phoneNumber}</p>
        </div>

        {/* Mostrar fecha de creación de la orden */}
        <div className="order-date">
          <p><strong>Fecha de Creación:</strong> {new Date(order.createdAt).toLocaleString()}</p>
        </div>

        {/* Actualizar el estado de la orden */}
        <div className="order-status-dropdown">
          <select
            className={`order-status-${newStatus}`} // Cambiar a newStatus
            value={newStatus} // Cambiar a newStatus
            onChange={handleStatusChange}
          >
            <option value="pending">Pendiente</option>
            <option value="processing">En proceso</option>
            <option value="shipped">Enviado</option>
            <option value="delivered">Entregado</option>
            <option value="cancelled">Cancelado</option>
          </select>
        </div>

        <div className="order-modal-buttons">
          <button onClick={handleUpdateClick}>Guardar</button>
          <button onClick={onClose}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsModal;
