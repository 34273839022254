const PaymentFailure = ({ error, onRetry }) => {
  return (
    <div className="payment-failure">
      <div className="failure-container">
        <h1>¡Ups! Algo salió mal con tu pago</h1>
        <p>{error || 'Hubo un problema al procesar tu pago'}</p>
        <div className="failure-actions">
          <button onClick={onRetry} className="retry-button">
            Intentar nuevamente
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailure; 