import React, { useState } from 'react';
import './StoreDrawer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import StorePanel from './StorePanel';

const StoreDrawer = ({ isOpen, storeName, stores, storeLogo }) => {
  const navigate = useNavigate();
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const handleStoreClick = (store) => {
    navigate(`/${store.route}`);
  };

  return (
    <div className={`store-drawer ${isOpen ? 'open' : ''}`}>
      <button>
        <FontAwesomeIcon icon={faStar} style={{color: "#74C0FC"}} />
      </button>
      <span className="store-drawer-name">{storeName}</span>
      <button className='store-panel-button' onClick={() => setIsPanelOpen(true)}>
        <FontAwesomeIcon icon={faAddressCard} style={{color: "#74C0FC"}} />
      </button>

      <StorePanel 
        isOpen={isPanelOpen}
        onClose={() => setIsPanelOpen(false)}
        storeName={storeName}
        storeUrl={window.location.href}
        storeLogo={storeLogo}
      />

      <div className="store-selector">
        {stores.map(store => (
          <div 
            key={store.id} 
            className="store-option"
            onClick={() => handleStoreClick(store)}
          >
            {store.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoreDrawer; 