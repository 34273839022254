import React, { useState, useCallback } from 'react';
import { DndContext, closestCorners, useSensor, useSensors, MouseSensor, TouchSensor, DragOverlay } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import DraggableProductItem from './DraggableProductItem';

const DraggableProduct = ({ products, setProducts, isOwner, openEditProductModal, openModal, updateOrder }) => {
    const [activeId, setActiveId] = useState(null);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor)
    );

    const handleDragStart = useCallback((event) => {
        setActiveId(event.active.id);
    }, []);

    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
    
        if (over) {
            const draggableProducts = products.filter(product => (!product.isHidden && product.stock > 0));
            const oldIndex = draggableProducts.findIndex(item => item.uuid === active.id);
            const newIndex = draggableProducts.findIndex(item => item.uuid === over.id);
    
            if (oldIndex !== -1 && newIndex !== -1) {
                const newDraggableProducts = arrayMove(draggableProducts, oldIndex, newIndex);
    
                const newProducts = [
                    ...newDraggableProducts,
                    ...products.filter(product => product.isHidden || product.stock <= 0)
                ];
    
                setProducts(newProducts);
    
                const productsToUpdate = newProducts.map((product, index) => ({
                    uuid: product.uuid,
                    position: index + 1  
                }));
    
                updateOrder(productsToUpdate);
            }
        }
    
        setActiveId(null);
    }, [products, setProducts, updateOrder]);
    

    const draggableProducts = products.filter(product => (!product.isHidden && product.stock > 0));
    const nonDraggableProducts = products.filter(product => (product.isHidden || product.stock <= 0));

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext items={draggableProducts.map(product => product.uuid)} strategy={rectSortingStrategy}>
                <div className="gallery-container">
                    {draggableProducts.map((product) => (
                        <DraggableProductItem
                            key={product.uuid}
                            product={product}
                            isOwner={isOwner}
                            openEditProductModal={openEditProductModal}
                            openModal={openModal}
                        />
                    ))}
                    {nonDraggableProducts.map((product) => (
                        <DraggableProductItem
                            key={product.uuid}
                            product={product}
                            isOwner={isOwner}
                            openEditProductModal={openEditProductModal}
                            openModal={openModal}
                        />
                    ))}
                </div>
            </SortableContext>
            <DragOverlay adjustScale={false} style={{ transition: 'none', transformOrigin: '0 0 ' }}>
                {activeId && (
                    <DraggableProductItem
                        product={products.find(product => product.uuid === activeId)}
                        isOwner={isOwner}
                        openEditProductModal={openEditProductModal}
                        openModal={openModal}
                    />
                )}
            </DragOverlay>
        </DndContext>
    );
};

export default DraggableProduct;
