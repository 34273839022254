import React, { createContext, useState, useContext, useEffect } from 'react';
import { setLoginModalCallback } from '../../services/UserService';

const LoginModalContext = createContext();

export const useLoginModal = () => useContext(LoginModalContext);

export const LoginModalProvider = ({ children }) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [initialView, setInitialView] = useState('login');

  const openLoginModal = (view = 'login') => {
    setInitialView(view);
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    setInitialView('login');
  };

  useEffect(() => {
    setLoginModalCallback(openLoginModal);
  }, []);

  return (
    <LoginModalContext.Provider value={{ isLoginModalOpen, openLoginModal, closeLoginModal, initialView }}>
      {children}
    </LoginModalContext.Provider>
  );
};
