import React, { useEffect, useRef } from 'react';
import { 
    getPaymentState, 
    isPaymentStateValid, 
    clearPaymentState 
} from '../../services/PaymentService';

const MPCheckout = ({ 
    preferenceId, 
    storeRoute,
    orderId 
}) => {
    useEffect(() => {
        // Remover cualquier instancia previa del modal
        const existingModal = document.querySelector('.mp-mercadopago-checkout-wrapper');
        if (existingModal) {
            existingModal.remove();
        }
        // Verificar que tenemos un orderId válido
        if (!orderId) {
            console.error('OrderId no definido:', orderId);
            return;
        }

        // Construir y redirigir directamente a la URL de pago
        const baseUrl = 'https://www.mercadopago.com.ar/checkout/v1/redirect';
        const successUrl = `${window.location.origin}/${storeRoute}/thankyou/${orderId}`;
        const failureUrl = `${window.location.origin}/${storeRoute}/checkout?error=payment-failed`;
        const pendingUrl = `${window.location.origin}/${storeRoute}/thankyou/${orderId}?status=pending`;
        
        const checkoutUrl = new URL(baseUrl);
        checkoutUrl.searchParams.append('pref_id', preferenceId);
        checkoutUrl.searchParams.append('success', successUrl);
        checkoutUrl.searchParams.append('failure', failureUrl);
        checkoutUrl.searchParams.append('pending', pendingUrl);

        // Guardar datos importantes en localStorage antes de redirigir
        localStorage.setItem('currentCheckout', JSON.stringify({
            orderId,
            storeRoute,
            timestamp: Date.now()
        }));

        // Redirigir
        window.location.href = checkoutUrl.toString();

        // Limpiar el script de MP si existe
        const mpScript = document.querySelector('script[src*="sdk.mercadopago.com"]');
        if (mpScript) {
            mpScript.remove();
        }
    }, [preferenceId, storeRoute, orderId]);

    return null;
};

export default MPCheckout; 