const API_URL = process.env.REACT_APP_API_URL;

export const initiateMPConnect = async (storeRoute) => {
  try {
    const response = await fetch(`${API_URL}/mercadopago/connect/${storeRoute}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    if (!response.ok) {
      throw new Error('Error al iniciar conexión con Mercado Pago');
    }

    const data = await response.json();
    return data.authUrl;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}; 