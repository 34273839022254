const API_URL = process.env.REACT_APP_API_URL;
const PAYMENT_STATE_KEY = 'paymentState';

export const createPaymentPreference = async (storeRoute, orderData) => {
  try {
    console.log('Creating payment preference:', { storeRoute, orderData }); // Debug

    const response = await fetch(`${API_URL}/payment/preference/${storeRoute}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      body: JSON.stringify(orderData)
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.message || 'Error al crear preferencia de pago');
    }

    console.log('Preference created successfully:', data);
    return data;
  } catch (error) {
    console.error('Error creating payment preference:', error);
    throw error;
  }
};

export const checkPaymentStatus = async (orderId) => {
  try {
    console.log('Checking payment status for orderId:', orderId);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payment/status/${orderId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    console.log('Payment status response:', response);

    if (!response.ok) {
      throw new Error(`Error al verificar el estado del pago: ${response.status}`);
    }

    const data = await response.json();
    console.log('Payment status data:', data);
    return data;
  } catch (error) {
    console.error('Error in checkPaymentStatus:', error);
    throw error;
  }
};

export const handleSecondFactorAuth = async (preferenceId) => {
  try {
      const response = await fetch(`${API_URL}/payment/auth-challenge`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
              preferenceId,
              challengeType: 'second_factor' 
          })
      });

      if (!response.ok) {
          throw new Error('Error en la autenticación');
      }

      return await response.json();
  } catch (error) {
      console.error('Error:', error);
      throw error;
  }
};

export const handlePaymentSuccess = async (paymentData) => {
    try {
        const { status, paymentId, storeRoute } = paymentData;
        console.log('Handling payment success:', paymentData); // Log para debugging

        // Verificar el estado del pago en el backend
        const paymentStatus = await checkPaymentStatus(paymentId);
        console.log('Payment status from backend:', paymentStatus); // Log para debugging

        return {
            status: paymentStatus.status,
            orderId: paymentStatus.orderId,
            storeRoute
        };
    } catch (error) {
        console.error('Error processing payment:', error);
        throw new Error('Error al procesar el pago');
    }
};

// Nuevos métodos para manejar el estado del pago
export const setPaymentState = (state) => {
    localStorage.setItem(PAYMENT_STATE_KEY, JSON.stringify({
        ...state,
        timestamp: Date.now()
    }));
};

export const getPaymentState = () => {
    const state = localStorage.getItem(PAYMENT_STATE_KEY);
    return state ? JSON.parse(state) : null;
};

export const clearPaymentState = () => {
    localStorage.removeItem(PAYMENT_STATE_KEY);
};

export const isPaymentStateValid = (state) => {
    if (!state || !state.timestamp) return false;
    // Estado válido por 5 minutos
    return (Date.now() - state.timestamp) < 300000;
};

export const waitForPaymentCompletion = async (orderId, timeout = 300000) => {
    const startTime = Date.now();
    
    while (Date.now() - startTime < timeout) {
        const status = await checkPaymentStatus(orderId);
        
        if (status === 'approved') {
            return { success: true, status };
        } else if (status === 'rejected' || status === 'cancelled') {
            return { success: false, status };
        }
        
        await new Promise(resolve => setTimeout(resolve, 2000));
    }
    
    return { success: false, status: 'timeout' };
};