import React, { useContext, useState, useEffect, useRef } from 'react';
import { CartContext } from '../components/Cart/CartContext'; // El contexto del carrito
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header/Header';  // Agregar el Header
import './CheckoutPage.css';
import { fetchUserData, updateUserProfile } from '../services/UserService'; // Importamos los servicios
import { useLoginModal } from '../components/Login/LoginModalContext'; // Agregar este hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { createPaymentPreference, checkPaymentStatus } from '../services/PaymentService'; // Importamos el servicio de pago
import MPCheckout from '../components/MercadoPago/MPCheckout';

const API_URL = process.env.REACT_APP_API_URL; // Asegúrate de que esta variable de entorno esté definida

const CheckoutPage = () => {
    const { cart, createOrder, clearCart, removeFromCart, addToCart, updateCartItemQuantity } = useContext(CartContext);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para verificar si el usuario está logueado
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phoneNumber: '',
        dni: ''
    });
    const location = useLocation();
    const navigate = useNavigate(); // Para redirigir a la página de login
    const [isEditing, setIsEditing] = useState(false); // Para controlar el modo edición
    const total = cart.reduce((acc, product) => acc + product.price * product.amount, 0).toFixed(2);
    const { openLoginModal } = useLoginModal(); // Agregar este hook
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [orderResponse, setOrderResponse] = useState(null);
    const [showMPCheckout, setShowMPCheckout] = useState(false);
    const [preferenceId, setPreferenceId] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentError, setPaymentError] = useState(null);
    const [paymentMessage, setPaymentMessage] = useState(null);
    const containerRef = useRef(null);
    const { orderId } = useParams();

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            fetchUserData(token).then(data => {
                setUserData({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    country: data.country,
                    postalCode: data.postalCode,
                    phoneNumber: data.phoneNumber,
                    dni: data.dni,
                });
            }).catch(error => {
                console.error('Error al obtener datos del usuario:', error);
            });
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    useEffect(() => {
        // Remover script anterior si existe
        const existingScript = document.querySelector('script[src*="sdk.mercadopago.com"]');
        if (existingScript) {
            existingScript.remove();
        }

        // No cargar el SDK de MP ya que usaremos redirección directa
    }, []);

    useEffect(() => {
        if (location.state?.paymentError) {
            setPaymentError(location.state.errorMessage);
            // Limpiar el estado de la ubicación
            window.history.replaceState({}, document.title);
        } else if (location.state?.paymentPending) {
            setPaymentMessage(location.state.message);
            window.history.replaceState({}, document.title);
        }
    }, [location]);

    useEffect(() => {
        const handlePaymentMessage = (event) => {
            if (event.origin === window.location.origin) {
                const { status, orderId } = event.data;
                if (status === 'approved') {
                    navigate(`/${location.pathname.split('/')[1]}/thank-you/${orderId}`);
                }
            }
        };

        window.addEventListener('message', handlePaymentMessage);
        return () => window.removeEventListener('message', handlePaymentMessage);
    }, []);

    useEffect(() => {
        // Verificar si hay error en la URL
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('error') === 'payment-failed') {
            setPaymentError('El pago no pudo ser procesado. Por favor, intenta nuevamente.');
            // Limpiar el error de la URL
            window.history.replaceState({}, document.title, location.pathname);
        }
    }, [location]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validateFields = () => {
        const requiredFields = {
            firstName: 'Nombre',
            lastName: 'Apellido',
            email: 'Email',
            phoneNumber: 'Teléfono',
            address: 'Dirección',
            city: 'Ciudad',
            dni: 'DNI'
        };

        const missingFields = Object.entries(requiredFields)
            .filter(([field]) => !userData[field])
            .map(([, label]) => label);

        if (missingFields.length > 0) {
            setPaymentError(`Por favor completa los siguientes campos: ${missingFields.join(', ')}`);
            return false;
        }

        // Validar formato de email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(userData.email)) {
            setPaymentError('Por favor ingresa un email válido');
            return false;
        }

        // Validar formato de teléfono (números y +)
        const phoneRegex = /^[0-9+\s-]+$/;
        if (!phoneRegex.test(userData.phoneNumber)) {
            setPaymentError('Por favor ingresa un número de teléfono válido');
            return false;
        }

        // Validar DNI (solo números)
        const dniRegex = /^[0-9]+$/;
        if (!dniRegex.test(userData.dni)) {
            setPaymentError('Por favor ingresa un DNI válido (solo números)');
            return false;
        }

        setPaymentError(null);
        return true;
    };

    const handleCreateOrder = async () => {
        try {
            if (!validateFields()) return;
            setIsProcessing(true);

            // Verificar que los productos tengan UUID
            const validProducts = cart.every(item => item.uuid);
            if (!validProducts) {
                throw new Error('Productos inválidos en el carrito');
            }

            // Calcular subtotal
            const subTotal = cart.reduce((sum, item) => 
                sum + (item.price * item.amount), 0
            );

            // Preparar los datos de la orden
            const orderData = {
                products: cart.map(item => ({
                    uuid: item.uuid,
                    quantity: item.amount,
                    price: item.price
                })),
                user: {
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    phoneNumber: userData.phoneNumber,
                    address: userData.address,
                    city: userData.city,
                    state: userData.state || '',
                    country: userData.country || 'Argentina',
                    postalCode: userData.postalCode || '',
                    dni: userData.dni
                },
                subTotal,
                route: location.pathname.split('/')[1]
            };

            // 1. Crear la orden
            const response = await fetch(`${process.env.REACT_APP_API_URL}/orders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: JSON.stringify(orderData)
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Error al crear la orden');
            }

            const orderResponse = await response.json();
            const orderId = orderResponse.data._id;

            // Verificar que tenemos un orderId válido
            if (!orderId) {
                throw new Error('No se recibió un ID de orden válido');
            }

            console.log('Order created with ID:', orderId); // Debug log

            // 2. Crear preferencia de MP con la orden creada
            const preferenceResponse = await createPaymentPreference(location.pathname.split('/')[1], {
                ...orderData,
                orderId: orderId // Asegurarse de que el orderId se pasa correctamente
            });

            // 3. Configurar URLs de redirección
            const baseUrl = 'https://www.mercadopago.com.ar/checkout/v1/redirect';
            const storeRoute = location.pathname.split('/')[1];
            const successUrl = `${window.location.origin}/${storeRoute}/thankyou/${orderId}`;
            const failureUrl = `${window.location.origin}/${storeRoute}/checkout?error=payment-failed`;
            const pendingUrl = `${window.location.origin}/${storeRoute}/thankyou/${orderId}?status=pending`;

            const checkoutUrl = new URL(baseUrl);
            checkoutUrl.searchParams.append('pref_id', preferenceResponse.id);
            checkoutUrl.searchParams.append('success', encodeURIComponent(successUrl));
            checkoutUrl.searchParams.append('failure', encodeURIComponent(failureUrl));
            checkoutUrl.searchParams.append('pending', encodeURIComponent(pendingUrl));
            checkoutUrl.searchParams.append('back_url', `${window.location.origin}/${storeRoute}/checkout`);

            // Guardar datos importantes
            localStorage.setItem('currentCheckout', JSON.stringify({
                orderId,
                storeRoute,
                timestamp: Date.now()
            }));

            // Limpiar el carrito antes de redirigir
            clearCart();

            // Redirigir a MP
            window.location.href = checkoutUrl.toString();

        } catch (error) {
            console.error('Error en el proceso de checkout:', error);
            setError(error.message);
        }
    };

    const handleMPClose = () => {
        setShowMPCheckout(false);
        setPreferenceId(null);
    };

    const handleMPError = (error) => {
        console.error('Error en el checkout de MP:', error);
        alert('Hubo un error al procesar el pago. Por favor, intente nuevamente.');
        setShowMPCheckout(false);
        setPreferenceId(null);
    };

    const handleLoginClick = () => {
        openLoginModal();
    };

    const handleRegisterClick = () => {
        openLoginModal('register'); // Asumiendo que el modal de login acepta un parámetro para mostrar el registro
    };

    const handleUpdateProfile = async () => {
        setIsLoading(true);
        try {
            // Solo enviamos los campos de dirección que queremos actualizar
            const addressData = {
                address: userData.address,
                city: userData.city,
                state: userData.state,
                country: userData.country,
                postalCode: userData.postalCode
            };

            await updateUserProfile(addressData);
            setIsEditing(false);

            // Mostrar mensaje de éxito (puedes implementar un sistema de notificaciones)
            alert('Dirección actualizada con éxito');
        } catch (error) {
            console.error('Error al actualizar el perfil:', error);
            // Mostrar mensaje de error
            alert('Error al actualizar la dirección');
        } finally {
            setIsLoading(false);
        }
    };

    const hasOptionalFields = userData.address && userData.city && userData.state &&
        userData.country && userData.postalCode;

    const renderOptionalFields = () => {
        if (isLoading) {
            return (
                <div className="skeleton">
                    <p className="skeleton-text">Cargando dirección...</p>
                    <p className="skeleton-text">Cargando ciudad...</p>
                    <p className="skeleton-text">Cargando estado...</p>
                    <p className="skeleton-text">Cargando país...</p>
                    <p className="skeleton-text">Cargando código postal...</p>
                </div>
            );
        }

        if (!hasOptionalFields || isEditing) {
            return (
                <form className="optional-fields-form">
                    <div className="full-width">
                        <label>Dirección</label>
                        <input
                            type="text"
                            name="address"
                            value={userData.address || ''}
                            onChange={handleInputChange}
                            placeholder="Calle y número"
                        />
                    </div>
                    <div className="input-row">
                        <div className="input-wrapper">
                            <label>Ciudad</label>
                            <input
                                type="text"
                                name="city"
                                value={userData.city || ''}
                                onChange={handleInputChange}
                                placeholder="Ingresa tu ciudad"
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>Estado/Provincia</label>
                            <input
                                type="text"
                                name="state"
                                value={userData.state || ''}
                                onChange={handleInputChange}
                                placeholder="Ingresa tu estado o provincia"
                            />
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="input-wrapper">
                            <label>País</label>
                            <input
                                type="text"
                                name="country"
                                value={userData.country || ''}
                                onChange={handleInputChange}
                                placeholder="Ingresa tu país"
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>Código Postal</label>
                            <input
                                type="text"
                                name="postalCode"
                                value={userData.postalCode || ''}
                                onChange={handleInputChange}
                                placeholder="Ej: 1234"
                            />
                        </div>
                    </div>
                    <button className="address-button" type="button" onClick={handleUpdateProfile}>
                        Guardar
                    </button>
                </form>
            );
        }

        return (
            <div className="address-display">
                <p><strong>Dirección:</strong> {userData.address}</p>
                <p><strong>Ciudad:</strong> {userData.city}</p>
                <p><strong>Estado:</strong> {userData.state}</p>
                <p><strong>País:</strong> {userData.country}</p>
                <p><strong>Código Postal:</strong> {userData.postalCode}</p>
                <button className='address-button' onClick={() => setIsEditing(true)}>
                    Editar
                </button>
            </div>
        );
    };

    const renderGuestFields = () => {
        return (
            <form className="guest-fields-container">
                <div className="field-group">
                    <h4>Información Personal</h4>
                    <div className="input-row">
                        <div className="input-wrapper">
                            <label>Nombre</label>
                            <input
                                type="text"
                                name="firstName"
                                value={userData.firstName || ''}
                                onChange={handleInputChange}
                                placeholder="Ingresa tu nombre"
                                required
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>Apellido</label>
                            <input
                                type="text"
                                name="lastName"
                                value={userData.lastName || ''}
                                onChange={handleInputChange}
                                placeholder="Ingresa tu apellido"
                                required
                            />
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="input-wrapper">
                            <label>Teléfono</label>
                            <input
                                type="tel"
                                name="phoneNumber"
                                value={userData.phoneNumber || ''}
                                onChange={handleInputChange}
                                placeholder="Ej: 1123456789"
                                required
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>DNI</label>
                            <input
                                type="text"
                                name="dni"
                                value={userData.dni || ''}
                                onChange={handleInputChange}
                                placeholder="Ingresa tu DNI"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="field-group">
                    <h4>Dirección de Envío</h4>
                    <div className="input-wrapper full-width">
                        <label>Dirección</label>
                        <input
                            type="text"
                            name="address"
                            value={userData.address || ''}
                            onChange={handleInputChange}
                            placeholder="Calle y número"
                            required
                        />
                    </div>
                    <div className="input-row">
                        <div className="input-wrapper">
                            <label>Ciudad</label>
                            <input
                                type="text"
                                name="city"
                                value={userData.city || ''}
                                onChange={handleInputChange}
                                placeholder="Ingresa tu ciudad"
                                required
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>Estado/Provincia</label>
                            <input
                                type="text"
                                name="state"
                                value={userData.state || ''}
                                onChange={handleInputChange}
                                placeholder="Ingresa tu estado o provincia"
                                required
                            />
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="input-wrapper">
                            <label>País</label>
                            <input
                                type="text"
                                name="country"
                                value={userData.country || ''}
                                onChange={handleInputChange}
                                placeholder="Ingresa tu país"
                                required
                            />
                        </div>
                        <div className="input-wrapper">
                            <label>Código Postal</label>
                            <input
                                type="text"
                                name="postalCode"
                                value={userData.postalCode || ''}
                                onChange={handleInputChange}
                                placeholder="Ej: 1234"
                                required
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    // Validación de datos antes de crear la orden
    const validateOrderData = () => {
        const requiredFields = [
            'firstName',
            'lastName',
            'email',
            'address',
            'city',
            'state',
            'country',
            'postalCode',
            'phoneNumber',
            'dni'
        ];

        const missingFields = requiredFields.filter(field => !userData[field]);

        if (missingFields.length > 0) {
            alert('Por favor, complete todos los campos requeridos');
            return false;
        }

        if (cart.length === 0) {
            alert('El carrito está vacío');
            return false;
        }

        return true;
    };

    // Agregar un manejador para mostrar/ocultar el error
    const handleCloseError = () => {
        setPaymentError(null);
    };

    const handleSuccess = (storeRoute, orderId) => {
        console.log('Payment successful, redirecting...');
        clearCart();
        navigate(`/${storeRoute}/thankyou/${orderId}`, { replace: true });
    };

    const handleError = (errorMessage) => {
        console.log('Payment error:', errorMessage);
        setPaymentError(errorMessage);
        setShowMPCheckout(false); // Asegurarse de desmontar el componente
    };

    return (
        <div className="checkout-page">
            <Header />
            {paymentError && (
                <div className="payment-error-message" style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    backgroundColor: '#ff4444',
                    color: 'white',
                    padding: '15px',
                    borderRadius: '5px',
                    zIndex: 1000,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <span>{paymentError}</span>
                    <button 
                        onClick={() => setPaymentError(null)}
                        style={{
                            marginLeft: '10px',
                            background: 'none',
                            border: 'none',
                            color: 'white',
                            cursor: 'pointer'
                        }}
                    >
                        ×
                    </button>
                </div>
            )}
            {paymentMessage && (
                <div className="payment-info-message">
                    <p>{paymentMessage}</p>
                    <button onClick={() => setPaymentMessage(null)}>×</button>
                </div>
            )}
            <div className="checkout-container">
                <div className="checkout-left">
                    <h2>Datos de Envío</h2>
                    {isLoggedIn ? (
                        <div>
                            <div className="required-fields">
                                <p><strong>Nombre:</strong> {userData.firstName}</p>
                                <p><strong>Apellido:</strong> {userData.lastName}</p>
                                <p><strong>Email:</strong> {userData.email}</p>
                                <p><strong>Teléfono:</strong> {userData.phoneNumber}</p>
                                <p><strong>DNI:</strong> {userData.dni}</p>
                            </div>
                            {renderOptionalFields()}
                        </div>
                    ) : (
                        <div>
                            <div className="guest-options">
                                <p>¿Ya tienes cuenta?</p>
                                <button className="checkout-login-btn" onClick={handleLoginClick}>
                                    Inicia sesión
                                </button>
                                <div className="register-option">
                                    <span>¿No tienes cuenta? </span>
                                    <button className="register-link" onClick={handleRegisterClick}>
                                        ¡Regístrate!
                                    </button>
                                </div>
                            </div>
                            <div className="guest-form">
                                <h3>Continuar como invitado</h3>
                                {renderGuestFields()}
                            </div>
                        </div>
                    )}
                </div>

                <div className="checkout-right">
                    <h2>Tu Carrito</h2>
                    {cart.map((product, index) => (
                        <div key={index} className="crt-pdct">
                            <div className="cart-pdct-section-1">
                                <img src={product.imageUrls.thumbnail} alt={product.name} className="thumbnail-image" />
                                <span className='crt-pdct-price'>${product.price}</span>
                            </div>
                            <div className='crt-pdct-section-2'>
                                <span className='crt-pdct-name'>{product.name}</span>
                                <div className='amount-handler'>
                                    <div className='amount-handler-wrapper'>
                                        <button className='remove-cart-item-btn' onClick={() => removeFromCart(product._id)}>
                                            <FontAwesomeIcon icon={faMinus} style={{ color: "#ffffff" }} />
                                        </button>
                                        <input
                                            type="number"
                                            className='crt-pdct-amount'
                                            value={product.amount}
                                            onChange={(e) => {
                                                const newAmount = parseInt(e.target.value, 10);
                                                if (!isNaN(newAmount) && newAmount >= 0 && newAmount <= product.stock) {
                                                    updateCartItemQuantity(product._id, newAmount);
                                                }
                                            }}
                                            min="0"
                                            max={product.stock}
                                        />
                                        <button
                                            className='add-cart-item-btn'
                                            onClick={() => addToCart(product)}
                                            disabled={product.amount >= product.stock}
                                        >
                                            <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
                                        </button>
                                    </div>
                                    <span className='crt-pdct-total'>${(product.amount * product.price).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="cart-total">
                        <strong>Total: ${total}</strong>
                    </div>
                    <button
                        className="confirm-order-btn"
                        onClick={() => {
                            if (validateOrderData()) {
                                handleCreateOrder();
                            }
                        }}
                        disabled={isProcessing || cart.length === 0}
                    >
                        {isProcessing ? 'Procesando...' : 'Ir a Pagar'}
                    </button>

                    {/* Contenedor oculto para MercadoPago */}
                    <div className="cho-container" style={{ display: 'none' }}></div>
                </div>
            </div>
            {showMPCheckout && preferenceId && (
                <div className="payment-container">
                    <MPCheckout
                        preferenceId={preferenceId}
                        orderId={orderResponse?._id}
                        storeRoute={location.pathname.split('/')[1]}
                        onClose={() => setShowMPCheckout(false)}
                        onSuccess={handleSuccess}
                        onError={handleError}
                        onProcessingChange={setIsProcessing}
                    />
                </div>
            )}
        </div>
    );
};

export default CheckoutPage;

