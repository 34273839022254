import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useState } from 'react'; // Asegúrate de importar useState
import { CartContext } from '../Cart/CartContext';
import './ProductModal.css';

const ProductModal = ({ product, onClose, onEdit, onDelete, esDueno: isOwner }) => {
  const { cart, addToCart, removeFromCart } = useContext(CartContext);
  const [showConfirmation, setShowConfirmation] = useState(false); // Añadir estado para el modal de confirmación

  // Buscar si el producto ya está en el carrito
  const cartItem = cart.find((item) => item._id === product._id);
  const quantity = cartItem ? cartItem.amount : 0;

  function crearEfectoparticles(e) {
    const cantidadparticles = 20;
    for (let i = 0; i < cantidadparticles; i++) {
      let particle = document.createElement('div');
      particle.style.left = `${e.clientX}px`;
      particle.style.top = `${e.clientY}px`;
      particle.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
      document.body.appendChild(particle);
      particle.className = 'particle';

      const animation = particle.animate([
        { transform: 'scale(3)', opacity: 1 },
        { transform: `scale(0) translate(${Math.random() * 200 - 100}px, ${Math.random() * 200 - 100}px)`, opacity: 0 }
      ], {
        duration: Math.random() * 500 + 300,
        easing: 'ease-out',
        iterations: 1
      });

      animation.onfinish = () => particle.remove();
    }
  }

  const handleAddToCart = (e) => {
    if (quantity < product.stock) {
      addToCart(product);
      crearEfectoparticles(e);
    }
  };

  const handleRemoveFromCart = () => {
    console.log('Removing product:', product);
    console.log('Current cart:', cart);
    if (quantity > 0) {
      const productId = product._id || product.uuid;
      if (productId) {
        removeFromCart(productId);
      } else {
      }
    } else {
    }
  };

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <button onClick={onClose} className='close-button-modal'>X</button>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        {isOwner && (
          <div className="edit-icon-container-modal">
            <div className="edit-icon-modal" onClick={() => onEdit(product)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </div>
          </div>
        )}
        <picture>
          <source srcSet={product.imageUrls.desktop} media="(min-width: 1024px)" />
          <source srcSet={product.imageUrls.tablet} media="(min-width: 768px)" />
          <source srcSet={product.imageUrls.mobile} media="(max-width: 767px)" />
          <img src={product.imageUrls.desktop} alt={product.name} />
        </picture>
        <div className="product-details">
          <p className='product-title'><b>{product.name}</b></p>
          <p className='product-price'><b>${product.price}</b></p>
          {isOwner && <p className='product-stock'><b>Stock: {product.stock}</b></p>}
          <p className='product-description'>{product.description}</p>
          <div className='product-btns-wrapper'>

            {!product.isHidden && product.stock > 0 && (
              quantity === 0 ? (
                <button className='cart-button' onClick={handleAddToCart}>Agregar al Carrito</button>
              ) : (
                <div className="quantity-control">
                  <button className='remove-product-modal-button' onClick={handleRemoveFromCart}>-</button>
                  <span>{quantity}</span>
                  <button className='add-product-modal-button' onClick={handleAddToCart} disabled={quantity >= product.stock}>+</button>
                </div>
              )
            )}

            {isOwner && (
              <button className='delete-button' onClick={() => setShowConfirmation(true)}>Borrar Producto</button>
            )}
          </div>

          {showConfirmation && (
            <div className='confirmation-modal'>
              <p>¿Estás seguro de que deseas borrar este producto?</p>
              <div className='confirmation-buttons'>
                <button className='confirm-button' onClick={() => {
                  onDelete(product.uuid); // Asegúrate de usar el identificador correcto
                  setShowConfirmation(false);
                }}>Sí</button>
                <button className='cancel-button' onClick={() => setShowConfirmation(false)}>No</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
