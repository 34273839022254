import React, { useState, useRef, useCallback } from 'react';
import { createStore } from '../../services/StoreServices'; // Importa la función createStore
import { useNavigate } from 'react-router-dom'; // Añadir esta importación

const CreateStoreModal = ({ onClose, onStoreCreated }) => {
    const navigate = useNavigate(); // Añadir el hook useNavigate
    const [name, setName] = useState('');
    const [route, setRoute] = useState('');
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    const fileInputRef = useRef(null);

    const handleLogoChange = useCallback((file) => {
        if (file) {
            setLogo(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setLogo(null);
            setLogoPreview(null);
        }
    }, []);

    const handleLogoClick = useCallback(() => {
        fileInputRef.current.click();
    }, []);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleLogoChange(e.dataTransfer.files[0]);
        }
    }, [handleLogoChange]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const storeData = await createStore(name, route, logo);
            console.log('Tienda creada:', storeData);
            onStoreCreated();
            navigate(`/${storeData.route}`);
            onClose();
        } catch (error) {
            console.error('Error al crear la tienda:', error);
        }
    };

    return (
        <div className="add-product-modal-backdrop" onClick={onClose}>
            <button onClick={onClose} className="close-button-modal">X</button>
            <div className="add-product-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Crear Tienda</h2>
                <div 
                    className={`image-upload-container ${isDragging ? 'drag-over' : ''}`}
                    onClick={handleLogoClick}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {logoPreview ? (
                        <img src={logoPreview} alt="Preview" className="uploaded-image-preview" />
                    ) : (
                        <div className="choose-file-button">
                            <span>Arrastra un logo o haz clic para seleccionar</span>
                        </div>
                    )}
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => handleLogoChange(e.target.files[0])}
                    style={{ display: 'none' }}
                    accept="image/*"
                />
                <form onSubmit={handleSubmit} className="add-product-form">
                    <label>
                        <input
                            className='add-product-input'
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Nombre de tu tienda"
                            style={{ fontSize: '18px' }} // Ajusta el tamaño del texto
                            required
                        />
                    </label>
                    <label>
                        <input
                            className='add-product-input'
                            type="text"
                            value={route}
                            onChange={(e) => {
                                const value = e.target.value;
                                setRoute(value.startsWith('/') ? value.slice(1) : value);
                            }}
                            placeholder="URL única de tu tienda (ej: /MiTienda)"
                            style={{ fontSize: '16px' }} // Ajusta el tamaño del texto
                            required
                        />
                    </label>
                    <button type="submit" className="submit-button">Crear</button>
                </form>
            </div>
        </div>
    );
};

export default CreateStoreModal;
